<template>
  <div class="grid">
    <div class="col-12">
      <Toast />
      <!--
			<CrudToolbar @create="openReceiptCreate" @delete="confirmDeleteSelected" @export="exportCSV($event)"/>
			-->
      <DataTable
        ref="dt"
        dataKey="id"
        filterDisplay="menu"
        class="p-datatable-sm"
        v-model:filters="filters"
        size="small"
        :filters="filters"
        :showGridlines="true"
        :globalFilterFields="globalFilterFields"
        :value="receipts"
        v-model:selection="selectedReceipt"
        :paginator="true"
        :lazy="true"
        sortMode="multiple"
        :multiSortMeta="multiSortMeta"
        :rows="pageSize"
        :totalRecords="totalRecords"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="rowPerPageOptions"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} receipts"
        @page="onPaging"
        @filter="onFilter"
        @sort="onSort"
        responsiveLayout="scroll"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
          >
            <!-- <h5 class="m-0">{{$t('receipt.list_title')}}</h5> -->
            <div class="my-2">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                :label="$t('button.clear')"
                class="p-button-outlined mr-2"
                @click="clearFilters()"
              />
              <Button
                type="button"
                icon="pi pi-plus"
                :label="$t('button.create')"
                class="p-button mr-2"
                @click="openReceiptCreate"
              />
              <Button
                type="button"
                icon="pi pi-download"
                :label="$t('button.export')"
                class="p-button mr-2"
                :disabled="receipts?.length == 0"
                @click="onExportExcel"
              />
              <!--<Button type="button" icon="pi pi-trash" :label="$t('button.delete')" class="p-button-danger mr-2" @click="confirmDeleteSelected"/>-->
            </div>
            <IconField iconPosition="left">
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <!-- need handle onChange event due to lazy option -->
              <InputText
                v-model="filters['global'].value"
                placeholder="Search..."
                @change="onGlobalFilter"
              />
            </IconField>
          </div>
        </template>
        <!--
				<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
				-->
        <Column headerStyle="min-width:10rem;" bodyStyle="text-align:left;">
          <template #body="slotProps">
            <div v-if="isActive(slotProps.data)">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="openReceiptEdit(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-box"
                class="p-button mr-2"
                @click="openReceiptPacking(slotProps.data)"
                v-if="is_packable(slotProps.data)"
                :severity="
                  slotProps.data.processStatus == 'inited'
                    ? 'success'
                    : 'danger'
                "
              /> -->
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                v-if="deletable(slotProps.data)"
                @click="confirmDeleteEntity(slotProps.data)"
              />
            </div>
          </template>
        </Column>
        <Column
          field="createdDate"
          :header="$t('receipt.receiptDate')"
          :sortable="true"
          dataType="date"
          headerStyle="width:15%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.receiptDate") }}</span>
            {{ this.formatDate(slotProps.data.createdDate) }}
          </template>
          <template #filter="{ filterModel }">
            <Calendar
              v-model="filterModel.value"
              dateFormat="mm/dd/yy"
              placeholder="mm/dd/yyyy"
            />
          </template>
        </Column>
        <Column field="shipmentCode" :header="$t('receipt.shipmentCodes')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
					<template #body="slotProps">
						<span class="p-column-title">{{$t('receipt.shipmentCodes')}}</span>
						{{slotProps.data.shipmentCodes}}
					</template>
					<template #filter="{filterModel}">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="$t('common.search_by_field',[$t('receipt.shipmentCodes')])"/>
					</template>
				</Column>
        <Column
          field="receiptCode"
          :header="$t('receipt.receiptCode')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.receiptCode") }}</span>
            <Button
              :label="slotProps.data.receiptCode"
              link
              class="p-button-text underline"
              @click="openReceiptView(slotProps.data)"
            />
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.receiptCode')])
              "
            />
          </template>
        </Column>
        <Column
          field="referenceShipmentCode"
          :header="$t('receipt.referenceShipmentCode')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.referenceShipmentCode") }}</span>
            {{ slotProps.data.shipmentDto?.referenceCode }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.referenceCode')])
              "
            />
          </template>
        </Column>
        <Column
          field="referenceCode"
          :header="$t('receipt.referenceCode')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.referenceCode") }}</span>
            {{ slotProps.data.referenceCode }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.referenceCode')])
              "
            />
          </template>
        </Column>
        
        <!-- <Column
          field="processStatus"
          filterField="processStatus"
          :header="$t('receipt.packingStatus')"
          :sortable="true"
          :showFilterMatchModes="false"
          headerStyle="width:10%; min-width:8rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{
              $t("receipt.packingStatus")
            }}</span>
            <span :style="statusColor(slotProps.data.processStatus)">
              {{ $t("receipt.processStatus_" + slotProps.data.processStatus) }}
            </span>
          </template>
          <template #filter="{ filterModel }">
            <div class="mb-3 font-bold">Status Picker</div>
            <MultiSelect
              v-model="filterModel.value"
              :options="processStatus"
              placeholder="Any"
              class="p-column-filter"
            >
              <template #option="slotProps">
                {{ $t("receipt.processStatus_" + slotProps.option) }}
              </template>
            </MultiSelect>
          </template>
        </Column> -->
        <Column
          field="packageNumber"
          :header="$t('receipt.pieces')"
          :sortable="true"
          dataType="numeric"
          headerStyle="width:8%; min-width:4rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.pieces") }}</span>
            {{ slotProps.data.packageNumber }}
          </template>
          <template #filter="{ filterModel }">
            <InputNumber
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.pieces')])
              "
            />
          </template>
        </Column>
        <Column
          field="weight"
          :header="$t('receipt.weight')"
          dataType="numeric"
          headerStyle="width:8%; min-width:4rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.weight") }}</span>
            {{ slotProps.data.weight }}
          </template>
          <template #filter="{ filterModel }">
            <InputNumber
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.weight')])
              "
            />
          </template>
        </Column>
        <Column
          field="cod"
          :header="$t('receipt.cod')"
          headerStyle="width:8%; min-width:4rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.cod") }}</span>
            <span v-if="slotProps.data.cod">
               <Checkbox binary v-model="slotProps.data.cod" disabled/>
            </span>           
          </template>
        </Column>
        <Column
          field="codAmount"
          :header="$t('receipt.codAmount')"
          dataType="numeric"
          headerStyle="width:12%; min-width:8rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.codAmount") }}</span>
            {{ slotProps.data.codAmount }}
          </template>
          <template #filter="{ filterModel }">
            <InputNumber
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.codAmount')])
              "
            />
          </template>
        </Column>
        <Column
          field="senderName"
          :header="$t('receipt.sender')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.sender") }}</span>
            {{ slotProps.data.senderName }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.senderName')])
              "
            />
          </template>
        </Column>
        <Column
          field="receiverName"
          :header="$t('receipt.recipient')"
          :sortable="true"
          headerStyle="width:14%; min-width:12rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.recipient") }}</span>
            {{ slotProps.data.recipientName }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.recipientName')])
              "
            />
          </template>
        </Column>
        <Column
          field="recipientProvince"
          :header="$t('address.province')"
          :sortable="true"
          headerStyle="width:14%; min-width:12rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("address.province") }}</span>
            {{ slotProps.data.recipientProvince }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('address.province')])
              "
            />
          </template>
        </Column>
        <!-- <Column
          field="paymentStatus"
          :header="$t('receipt.paymentStatus')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{
              $t("receipt.paymentStatus")
            }}</span>
            {{ slotProps.data.paymentStatus }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.paymentStatus')])
              "
            />
          </template>
        </Column> -->
        <!-- <Column
          field="paymentMethod"
          :header="$t('receipt.paymentMethod')"
          :sortable="true"
          headerStyle="width:14%; min-width:10rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{
              $t("receipt.paymentMethod")
            }}</span>
            {{ formatPaymentMethod(slotProps.data.paymentMethod) }}
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              :placeholder="
                $t('common.search_by_field', [$t('receipt.paymentMethod')])
              "
            />
          </template>
        </Column> -->
        <Column
          field="note"
          :header="$t('receipt.note')"
          :sortable="true"
          headerStyle="width:14%; min-width:15rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("receipt.note") }}</span>
            {{ slotProps.data.note }}
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- <Dialog
      v-model:visible="showDeleteReceiptDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="entity"
          >{{ $t("receipt.delete_confirm") }} <b>{{ entity.receiptCode }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="showDeleteReceiptDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteReceipt"
        />
      </template>
    </Dialog> -->
  </div>
</template>

<script>
import formatter from "@/utils/formatter";
import ReceiptService from "@/service/ReceiptService";
//import CrudToolbar from '@/pages/common/CrudToolbar'
import filter from "@/mixins/filter";
import { Consts } from "@/utils/consts";
import { writeFile, utils } from "xlsx";
import { converter } from "@/utils/common";
import { serializeOrders, serializeFilters } from "@/utils/params";

export default {
  components: {},
  mixins: [filter],
  data() {
    return {
      showDeleteReceiptDialog: false,
      receipts: [],
      selectedReceipt: null,
      entity: {},
      filters: {},
      multiSortMeta: [{ field: "createdDate", order: -1 }],
      globalFilterFields: [
        "receiptCode",
        "senderName",
        "recipientName",
        "recipientProvince",
        "note",
      ],
      filterOptions: {
        page: 0,
        size: Consts.DEFAULT_PAGE_ROWS,
      },
      submitted: false,
      totalRecords: 0,
      processStatus: [
        "inited",
        "packed",
        "shipped",
        "stored",
        "processing",
        "delivered",
      ],
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
      pageSize: Consts.DEFAULT_PAGE_ROWS,
      rowPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
    };
  },
  created() {
    this.initGlobalFilter();
  },
  mounted() {
    this.initColumnFilters(this.$refs.dt, this.filters);
    this.filterOptions.sorts = serializeOrders({
      multiSortMeta: this.multiSortMeta,
    });
    this._loadEntities();
  },
  methods: {
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size = pageEvent.rows;
      this._loadEntities();
    },
    onFilter(filterEvent) {
      let filter = serializeFilters(filterEvent);
      if (this.filterOptions.filter != filter) {
        this.filterOptions.filter = filter;
        this._loadEntities();
      }
    },
    onGlobalFilter() {
      let filters = { ...this.filters };
      let value = this.filters.global.value;
      filters["_global_"] = {
        operator: "or",
        constraints: this.globalFilterFields.map((field) => ({
          matchMode: field,
          value,
        })),
      };
      this.onFilter({ filters });
    },
    onSort(sortEvent) {
      let sorts = serializeOrders(sortEvent);
      if (this.filterOptions.sorts !== sorts) {
        this.filterOptions.sorts = sorts;
        this._loadEntities();
      }
    },
    _loadEntities() {
      ReceiptService.getAll(this.filterOptions).then((data) => {
        if (Array.isArray(data.content)) {
          data.content.forEach((item) => {
            if (item.createdDate) {
              item.createdDate = new Date(item.createdDate);
            }
          });
          this.receipts = data.content;
          this.totalRecords = data.totalElements;
        } else {
          this.receipts = [];
          this.totalRecords = 0;
        }
      });
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    formatDate(jsDate) {
      return formatter.formatDate(jsDate, Consts.FORMAT_DATE_US);
    },
    formatPaymentMethod(method) {
      let key = "receipt.paymentMethods." + method;
      let label = this.$t("receipt.paymentMethods." + method);
      if (label == key) {
        label = method;
      }
      return label;
    },
    statusColor(status) {
      return status == "packed"
        ? {
            //'background-color': '#d32f2f',
            color: "#d32f2f",
          }
        : {};
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    openReceiptCreate() {
      this.$router.push({ name: "users_receipt_edit" });
    },
    openReceiptEdit(receipt) {
      this.$router.push({
        name: "users_receipt_edit",
        params: { id: receipt.id },
      });
    },
    openReceiptView(receipt) {
      this.$router.push({
        name: "users_receipt_view",
        params: { id: receipt.id },
      });
    },
    previewReceipt(receipt) {
      this.$router.push({ name: "print_receipt", params: { id: receipt.id } });
    },
    openReceiptPacking(receipt) {
      this.$router.push({
        name: "agent_package_edit",
        params: { receiptId: receipt.id },
      });
    },
    //Not deleted, not discarded
    isActive(entity) {
      return (
        entity.processStatus != "deleted" && entity.processStatus != "discarded"
      );
    },
    deletable(entity) {
      return entity.processStatus == "inited";
    },
    confirmDeleteEntity(entity) {
      this.entity = entity;
      if (entity.processStatus == "inited") {
        this.$confirm.require({
          message: `Are you sure you want to delete receipt ${entity.receiptCode}?`,
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.deleteReceipt();
          },
          reject: () => {},
        });
      }
    },
    deleteReceipt() {
      if (this.entity) {
        ReceiptService.delete(this.entity.id)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: this.$t("receipt.delete_successfully", {
                receiptCode: this.entity.receiptCode,
              }),
              life: 3000,
            });
          })
          .finally(() => {
            this._loadEntities();
            this.entity = null;
          });
      }
    },
    is_packable(item) {
      if (!item || !item.processStatus) return false;
      return item.processStatus == "inited" || item.processStatus == "packed";
    },
    onExportExcel() {
      //Create the sheet
      const sheet = this._createReceiptSheet();
      var wb = utils.book_new(); // make Workbook of Excel
      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, sheet, "Receipts"); // sheetAName is name of Worksheet
      // export Excel file
      const currentDate = new Date();
      const fileName = "receipt-" + currentDate.getTime() + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createReceiptSheet() {
      const hdrDate = this.$t("receipt.receiptDate");
      const hdrCode = this.$t("receipt.receiptCode");
      const hdrStatus = this.$t("receipt.packingStatus");
      const hdrPieces = this.$t("receipt.pieces");
      const hdrWeightLbs = this.$t("receipt.weight");
      const hdrWeightKg = this.$t("receipt.weightKg");
      const hdrSender = this.$t("receipt.sender");
      const hdrSenderPhone = this.$t("receipt.senderPhone");
      const hdrSenderState = this.$t("receipt.senderState");
      // const hdrSenderCity = this.$t("receipt.senderCity");
      // const hdrSenderCounty = this.$t("receipt.senderCounty");
      const hdrSenderAddress = this.$t("receipt.senderAddress");
      const hdrRecipient = this.$t("receipt.recipient");
      const hdrProvince = this.$t("address.province");
      const hdrDistrict = this.$t("address.district");
      // const hdrWard = this.$t("address.ward");
      const hdrAddress = this.$t("receipt.recipientAddress");
      const hdrRecipientPhone = this.$t("receipt.recipientPhone");
      const hdrDescription = this.$t("receipt.description");
      const sheetData = this.receipts.map((receipt, ind) => {
        let senderAddress = [];
        let recipientAddress = [];
        if (receipt.recipientAddress) {
          recipientAddress.push(receipt.recipientAddress);
        }
        if (receipt.recipientWard) {
          recipientAddress.push(receipt.recipientWard);
        }
        if (receipt.recipientDistrict) {
          recipientAddress.push(receipt.recipientDistrict);
        }
        if (receipt.recipientProvince) {
          recipientAddress.push(receipt.recipientProvince);
        }
        if (receipt.senderAddress) {
          senderAddress.push(receipt.senderAddress);
        }
        if (receipt.senderCity) {
          senderAddress.push(receipt.senderCity);
        }
        if (receipt.senderCounty) {
          senderAddress.push(receipt.senderCounty);
        }
        return {
          ["Index"]: ind + 1,
          [hdrDate]: receipt.createdDate,
          [hdrCode]: receipt.receiptCode,
          [hdrStatus]: this.$t(
            "receipt.processStatus_" + receipt.processStatus
          ),
          [hdrPieces]: receipt.packageNumber,
          [hdrWeightLbs]: receipt.weight,
          [hdrWeightKg]: converter.tokg(receipt.weight),
          [hdrSender]: receipt.senderName,
          [hdrSenderPhone]: receipt.senderPhone,
          [hdrSenderState]: receipt.senderState,
          // [hdrSenderCity]: receipt.senderCity,
          // [hdrSenderCounty]: receipt.senderCounty,
          // [hdrSenderAddress]: receipt.senderAddress,
          [hdrSenderAddress]: senderAddress.join(","),
          [hdrRecipient]: receipt.recipientName,
          [hdrRecipientPhone]: receipt.recipientPhone,
          [hdrAddress]: recipientAddress.join(","),
          [hdrProvince]: receipt.recipientProvince,
          [hdrDistrict]: receipt.recipientDistrict,
          // [hdrWard]: receipt.recipientWard,
          // [hdrAddress]: receipt.recipientAddress,
          [hdrDescription]: receipt.packageItemDtos
            ?.map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(sheetData, {
        origin: "A1",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 }, //Created date
        { wch: 14 }, //ReceiptCode
        { wch: 8 }, //Status
        { wch: 8 }, //PackageNumber
        { wch: 8 }, //Weight lbs
        { wch: 8 }, //Weightkg
        { wch: 20 }, //Sender name
        { wch: 12 }, //Sender phone
        { wch: 20 }, //Sender State
        // { wch: 20 }, //Sender City
        // { wch: 20 }, //Sender County
        { wch: 30 }, //Sender Address
        { wch: 20 }, //Recipient's name
        { wch: 12 }, //Recipient's phone
        { wch: 30 }, //Address
        { wch: 20 }, //Province
        { wch: 20 }, //District
        // { wch: 20 }, //Ward
        { wch: 30 },
      ];
      return sheet;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
</style>
